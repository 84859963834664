// Override default variables before the import
//$body-bg: #eee;

$enable-shadows: true;
$enable-reduced-motion: false;

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

.table-clickable tbody tr {
    cursor: pointer;

    &:hover {
        background-color: $light;
    }
}

table {
    table-layout: fixed;
}